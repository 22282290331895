<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 16:29:32
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-13 22:32:31
-->
<template>
  <div class="page-box">
    <list @load="getList">
      <div class="header flex-x center" v-if="$route.query.status == 4">
        <span class="header__title">上月待结算金额</span>
        <span class="header__price grow">{{ amount }}</span>
        <div class="header__picker flex-x center" @click="showMonth = true">
          <span class="grow">{{ month }}月</span>
          <div class="header__arrow"></div>
        </div>
      </div>

      <div class="order" v-for="(item, index) in list" :key="index">
        <order-card
          :info="item"
          :btns="checkBtn(item, btns)"
          :showSeal="!!checkIcon(item, icons)"
          :sealIcon="checkIcon(item, icons)"
          @onclickbtn="clickBtn"
          @ondetail="detail"
        />
      </div>
    </list>

    <!-- 勘察时间 -->
    <survey v-model="showTime" @onsubmit="submitSurvey" />

    <!-- 上门时间 -->
    <survey v-model="showVisitTime" @onsubmit="submitVisitTime" />

    <msg-box v-model="showNone" @onclick="$router.back()" />

    <turn-down
      v-model="showTurnDown"
      @onsubmit="submitTurnDown"
      ref="turnDown"
    ></turn-down>

    <popup v-model="showMonth" position="bottom">
      <datetime-picker
        type="year-month"
        @cancel="showMonth = false"
        @confirm="selectMonth"
      />
    </popup>
  </div>
</template>

<script>
import turnDown from "../../../components/turnDown/turnDown";
import Survey from "../../../components/Survey/Survey";
import { List, Toast, Popup, DatetimePicker } from "vant";
import msgBox from "../../../components/msgBox/msgBox";
import TurnDown from "../../../components/turnDown/turnDown.vue";
import orderCard from "@/components/OrderCard/OrderCard";
import { orderList } from "../../../utils/api/otherWorkOrder";
import { order, fillTime, settlementAmount } from "../../../utils/api/service";
import pageLoader from "../../../utils/load_per_page";
export default {
  components: {
    "order-card": orderCard,
    list: List,
    "msg-box": msgBox,
    survey: Survey,
    "turn-down": turnDown,
    popup: Popup,
    "datetime-picker": DatetimePicker,
  },
  data() {
    return {
      turnDownIcon: require("../../../assets/images/user/review_turndown.png"), // 驳回图片

      listLoader: null,

      list: [],

      showNone: false, // 显示没有列表

      showTime: false, // 显示勘察时间

      showVisitTime: false, // 显示上门时间

      showTurnDown: false, // 显示驳回弹窗

      prepareInfo: 0, // 准备操作的内容

      showMonth: false, // 显示选择年月

      year: new Date().getFullYear(), // 当前选中年
      month: new Date().getMonth() + 1, // 当前选中月

      amount: 0, // 结算金额

      // 图标
      icons: [
        {
          // 勘察
          img: require("../../../assets/images/user/review_survey.png"),
          condition: { otherStatus: 1, "label.status": 3 },
        },
        {
          // 驳回
          img: require("../../../assets/images/user/review_turndown.png"),
          condition: { otherStatus: 1, "label.status": 4, "label.review": 2 },
        },
        {
          // 勘察
          img: require("../../../assets/images/user/review_survey.png"),
          condition: { otherStatus: 1, "label.status": 4 },
        },
        {
          // 驳回
          img: require("../../../assets/images/user/review_turndown.png"),
          condition: { otherStatus: 2, "label.status": 4, "label.review": 2 },
        },
        {
          // 验收
          img: require("../../../assets/images/user/review_waitacceptance.png"),
          condition: { otherStatus: 3 },
        },
        {
          // 待结算
          img: require("../../../assets/images/user/review_waitsettle.png"),
          condition: { otherStatus: 4, "label.status": 0 },
        },
        {
          // 已结算
          img: require("../../../assets/images/user/review_waitsettle.png"),
          condition: { otherStatus: 4, "label.status": 1 },
        },
      ],

      // 按钮
      btns: [
        {
          text: "驳回",
          method: "turnDown",
          condition: { otherStatus: 0 },
        },
        {
          text: "接单",
          method: "order",
          type: "primary",
          condition: { otherStatus: 0 },
        },
        {
          text: "去勘察",
          method: "survey",
          condition: { otherStatus: 1, "label.status": 1 },
        },
        {
          text: "勘察中",
          type: "lock",
          condition: { otherStatus: 1, "label.status": 2 },
        },
        {
          text: "报价",
          method: "offer",
          type: "primary",
          condition: { otherStatus: 1, "label.status": 3 },
        },
        {
          text: "报价待审核",
          type: "lock",
          condition: { otherStatus: 1, "label.status": 4, "label.review": 0 },
        },
        {
          text: "报价",
          method: "offer",
          type: "primary",
          condition: { otherStatus: 1, "label.status": 4, "label.review": 2 },
        },
        {
          text: "上门时间",
          method: "visitTime",
          condition: { otherStatus: 2, "label.status": 1 },
        },
        {
          text: "上门中",
          type: "lock",
          condition: { otherStatus: 2, "label.status": 2 },
        },
        {
          text: "提交报告",
          type: "primary",
          method: "submitReport",
          condition: { otherStatus: 2, "label.status": 3 },
        },
        {
          text: "报告待审核",
          type: "lock",
          condition: { otherStatus: 2, "label.status": 4 },
        },
      ],
    };
  },

  created() {
    if (this.$route.query.status == 4) this.getAmount();
  },

  methods: {
    // 获取当月结算金额 status4
    getAmount() {
      settlementAmount({
        month: `${this.year}-${this.month}`,
      }).then((res) => {
        this.amount = res.data.data.amount;
      });
    },

    // 获取列表
    getList() {
      if (!this.listLoader)
        this.listLoader = new pageLoader(
          orderList,
          "data.data.list.data",
          true,
          "data.data.list.last_page"
        );
      this.listLoader
        .load({
          status: this.$route.query.status,
          month:
            this.$route.query.status == 4 ? `${this.year}-${this.month}` : "",
        })
        .then((res) => {
          if (res && res.length === 0) this.showNone = true;
          if (res && res.length) {
            this.showNone = false;
            this.list = res;
          }
        })
        .catch((res) => {});
    },

    // 重新获取列表
    reGetList() {
      this.listLoader.reLoad();
      this.getList();
    },

    // 检查按钮并返回应该显示
    checkBtn(info, btns) {
      let result = [];

      for (let i = 0; i < btns.length; i++) {
        // 如果按钮没有条件
        if (!btns[i].condition) {
          result.push(btns[i]);
          continue;
        }

        let condition = btns[i].condition;

        if (this.checkCondition(info, condition)) result.push(btns[i]);
      }

      return result;
    },

    // 检查图标
    checkIcon(info, icons) {
      for (let i = 0; i < icons.length; i++) {
        let condition = icons[i].condition;

        if (this.checkCondition(info, condition)) {
          return icons[i].img;
        }
      }
      return "";
    },

    // 检查条件内容
    checkCondition(obj, condition) {
      let checkPassed = true; // 检查通过
      for (let j in condition) {
        let position = j.split(".");
        let value = obj;
        while (position.length > 0) {
          value = value[position[0]];
          position.splice(0, 1);
        }
        if (condition[j] !== value) {
          checkPassed = false;
          break;
        }
      }
      return checkPassed;
    },

    // 点击按钮
    clickBtn(e) {
      if (!e.detail.method) return false;
      this[e.detail.method](e.info.id, e.info);
    },

    // 驳回
    turnDown(id, info, btn) {
      this.prepareInfo = info;
      this.showTurnDown = true;
    },

    // 发送驳回
    submitTurnDown(e) {
      order({
        id: this.prepareInfo.id,
        review: 2,
        reason: e.detail.text,
      })
        .then((res) => {
          Toast.success("驳回成功");
          this.showTurnDown = false;
          this.$refs.turnDown.clear();
          this.reGetList();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 接单
    order(id) {
      order({
        id,
        review: 1,
      }).then((res) => {
        Toast.success("接单成功");
        this.reGetList();
      });
    },

    // 去勘察
    survey(id, info) {
      this.showTime = true;
      this.prepareInfo = info;
    },

    // 上传勘察
    submitSurvey(e) {
      let info = e.detail;
      info.hour = JSON.stringify(info.hour);
      fillTime({
        id: this.prepareInfo.id,
        type: 0,
        ...info,
      })
        .then((res) => {
          Toast.success("填写成功");
          this.showTime = false;
          this.reGetList();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 报价
    offer(id) {
      this.$router.push({
        name: "ServiceOffer",
        query: {
          id,
        },
      });
    },

    // 上门时间
    visitTime(id, info) {
      this.showVisitTime = true;
      this.prepareInfo = info;
    },

    // 上传勘察
    submitVisitTime(e) {
      let info = e.detail;
      info.hour = JSON.stringify(info.hour);
      fillTime({
        id: this.prepareInfo.id,
        type: 1,
        ...info,
      })
        .then((res) => {
          Toast.success("填写成功");
          this.showVisitTime = false;
          this.reGetList();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 提交报告
    submitReport(id) {
      this.$router.push({
        name: "ServiceReport",
        query: {
          id,
        },
      });
    },

    // 选择年月
    selectMonth(e) {
      this.year = e.getFullYear();
      this.month = e.getMonth() + 1;
      this.showMonth = false;
      this.getAmount();
      this.reGetList();
    },

    // 详情
    detail(e) {
      this.$router.push({
        name: "ServiceOrderDetail",
        query: {
          id: e.info.id,
        },
      });
    },
  },
};
</script> 

<style src="@/assets/css/review/newTicket.css" scoped>
</style>

<style scoped>
.header {
  width: 100%;
  height: 98px;
  position: sticky;
  z-index: 99999;
  top: 0;
  background-color: white;
  box-sizing: border-box;
  padding: 0 33px;
}

.header__title {
  font-size: 28px;
  color: #222;
}

.header__price {
  font-size: 46px;
  color: var(--main-color);
  text-decoration: underline;
  margin-left: 18px;
}

.header__picker {
  width: 204px;
  height: 58px;
  border: 1px solid #999;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 12px 0 21px;
  font-size: 28px;
}

.header__arrow {
  width: 42px;
  height: 42px;
  background-image: url("../../../assets/images/review/form_arrow.png");
  background-size: contain;
  background-repeat: no-repeat;
}
</style>